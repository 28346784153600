import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { BookingsColumnDefs as InvokeBookingsColumnDefs } from "./utils/Formatters";
import Header from "../../components/Header/Header";
import BookingHeader from "./components/BookingHeader";
import styles from "./Bookings.module.scss";
import GridComponent from "../../components/GridComponent/GridComponent";
import { contextItems } from "./utils/Variables";
import { GET_ACTIVE_GUIDES, SUBSCRIBE_ACTIVE_GUIDES } from "../../api/Guides";
import {
  GET_AVAILABLE_VEHICLES,
  SUBSCRIBE_AVAILABLE_VEHICLES,
} from "../../api/Vehicles";
import useCellEditing from "./utils/hooks/useCellEditing";
import useBookings from "./utils/hooks/useBookings";

function Bookings() {
  const navigate = useNavigate();
  const {
    data,
    loading,
    error,
    dateRange,
    setDateRange,
    initialFilterState,
    setInitialFilterState,
    refetchBookings,
  } = useBookings();

  const [isEditing, setIsEditing] = useState(false);
  // const [initialFilterState, setInitialFilterState] = useState({
  //   filters: {
  //     tour_date: {
  //       _gte: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
  //       _lte: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
  //     },
  //   },
  // });

  // useEffect(() => {
  //   if (dateRange[0].startDate !== dateRange[0].endDate) {
  //     setInitialFilterState({
  //       filters: {
  //         tour_date: {
  //           _gte: moment(dateRange[0].startDate).format('YYYY-MM-DD'),
  //           _lte: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
  //         },
  //       },
  //     });
  //   }
  // }, [dateRange]);

  // const initialFilterVar = makeVar(initialFilterState);
  // const initialFilter = useReactiveVar(initialFilterVar);

  // const { loading, data, error } = useSubscription(FETCH_BOOKINGS_STATUS, {
  //   shouldResubscribe: true,
  //   variables: initialFilter,
  // });

  /* Guides & Vehicles Subscription */
  /* Fetching Guides */
  // const { data: guides = [] } = useSubscription(SUBSCRIBE_ACTIVE_GUIDES, {
  //   shouldResubscribe: true,
  // });
  // /* Fetching Vehicles */
  // const { data: vehicles = [] } = useSubscription(SUBSCRIBE_AVAILABLE_VEHICLES, {
  //   shouldResubscribe: true,
  // });

  /* Guides & Vehicles Query */
  /* Fetching Guides */
  const { data: guides = [] } = useQuery(GET_ACTIVE_GUIDES);
  /* Fetching Vehicles */
  const { data: vehicles = [] } = useQuery(GET_AVAILABLE_VEHICLES);

  const { stateAndActions } = useCellEditing({
    content: { vehicles, guides },
    methods: {
      refetchBookings,
    },
  });

  const BookingsColumnDefs = useMemo(() => {
    if (typeof guides !== "object" && typeof vehicles !== "object") return;
    return InvokeBookingsColumnDefs(
      guides?.guides,
      vehicles?.vehicles,
      stateAndActions
    );
  }, [guides, vehicles]);

  const getContextMenuItems = useCallback(
    (params) => {
      const newItems = contextItems.map((item) => ({
        ...item,
        action: () =>
          navigate({
            pathname: params.node.data.id,
            hash: item.name.toLowerCase(),
          }),
      }));
      return [...newItems, ...params.defaultItems];
    },
    [navigate]
  );

  if (error) {
    return (
      <>
        <Header />
        <main className={styles.Bookings__error}>
          <div>
            <h2>Something went wrong...</h2>
            <div>{`Error: ${error.message}`}</div>
          </div>
        </main>
      </>
    );
  }

  return (
    <>
      <Header />
      <main className={styles.Bookings__main}>
        <Outlet context={{ isEditing, setIsEditing }} />
        <div className={styles.bookings_grid_container}>
          {/* {loading
            ? (
              <div className={styles.Bookings__loader}>
                <div id={styles['Bookings-information-loader']}> </div>
                <p className={styles.loading}>Loading...</p>
              </div>
            )
            : typeof guides === 'object' && typeof vehicles === 'object'
            && typeof BookingsColumnDefs === 'object' && ( */}
          <GridComponent
            gridTitle="bookings"
            isLoading={
              typeof guides === "object" && typeof vehicles === "object"
                ? loading
                : true
            }
            suppressRowGroupHidesColumns
            stateAndActions={stateAndActions}
            data={data?.bookings}
            columnDefs={BookingsColumnDefs}
            updating={!data?.bookings}
            getContextMenuItems={getContextMenuItems}
            content={{ vehicles, guides }}
          >
            <BookingHeader
              {...{ setInitialFilterState, dateRange, setDateRange }}
            />
          </GridComponent>
          {/* )} */}
        </div>
      </main>
    </>
  );
}

export default Bookings;
