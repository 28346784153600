import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { FETCH_VEHICLES } from "../../api/Vehicles";
import Header from "../../components/Header/Header";
import "./Guides.scss";
import { FETCH_GUIDES, SUBSCRIBE_GUIDES } from "../../api/Guides";

function Guides() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, refetch } = useSubscription(SUBSCRIBE_GUIDES, {
    fetchPolicy: "no-cache",
  });

  const getHeader = () => {
    let header = "";
    if (location.pathname.includes("edit")) {
      header = (
        <h2 className="heading_styles">
          <i
            className="far fa-arrow-left"
            onClick={() => navigate("/guides")}
          />
          <span>Edit Guide</span>
        </h2>
      );
    } else if (location.pathname.includes("create")) {
      header = (
        <h2 className="heading_styles">
          <i
            className="far fa-arrow-left"
            onClick={() => navigate("/guides")}
          />
          <span>Create Guide</span>
        </h2>
      );
    } else {
      header = <h2 className="heading_styles">Guides</h2>;
    }
    return header;
  };

  return (
    <>
      <Header />
      <div className="Vehicles__container">
        <div className="card">
          <div className="card-header">{getHeader()}</div>
          <main className="Vehicles__main">
            <Outlet context={{ data, refetch }} />
          </main>
        </div>
      </div>
    </>
  );
}

export default Guides;
