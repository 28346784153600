import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

function Page2({
  formik,
  units,
  selectedUnits,
  setSelectedUnits,
}) {
  const { values, errors, handleChange } = formik;
  const { productId } = useParams();

  const options = units?.map((unit) => ({
    value: unit.id,
    label: unit.label,
  }));

  const handleUnitsChange = (value) => {
    const units = [];
    value.map((unit) => {
      units.push({ unit_id: unit.value, product_id: productId });
    });
    setSelectedUnits(value);
    formik.setFieldValue('product_units', units);
  };

  return (
    <div className="EditForm__form-page2">
      <div>
        <Form.Group>
          <Form.Label>Booking Cutoff</Form.Label>
          <InputGroup className={errors.booking_cutoff ? 'is-invalid' : ''}>
            <Form.Control
              id="booking_cutoff"
              name="booking_cutoff"
              type="number"
              placeHolder="Enter booking cutoff"
              onChange={handleChange}
              value={values.booking_cutoff}
              className={errors.booking_cutoff ? 'is-invalid' : ''}
            />
            <Form.Select
              id="bookingCutoffUnits"
              name="bookingCutoffUnits"
              onChange={handleChange}
              value={values.bookingCutoffUnits}
            >
              <option value="hours">Hours</option>
              <option value="days">Days</option>
            </Form.Select>
          </InputGroup>
          <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Cancel Cutoff</Form.Label>
          <InputGroup className={errors.cancel_cutoff ? 'is-invalid' : ''}>
            <Form.Control
              id="cancel_cutoff"
              name="cancel_cutoff"
              type="number"
              placeHolder="Cancel booking cutoff"
              onChange={handleChange}
              value={values.cancel_cutoff}
              className={errors.cancel_cutoff ? 'is-invalid' : ''}
            />
            <Form.Select
              id="cancelCutoffUnits"
              name="cancelCutoffUnits"
              onChange={handleChange}
              value={values.cancelCutoffUnits}
            >
              <option value="hours">Hours</option>
              <option value="days">Days</option>
            </Form.Select>
          </InputGroup>
          <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>Duration</Form.Label>
          <InputGroup className={errors.duration ? 'is-invalid' : ''}>
            <Form.Control
              id="duration"
              name="duration"
              placeHolder="Enter Duration"
              type="number"
              onChange={handleChange}
              value={values.duration}
              className={errors.duration ? 'is-invalid' : ''}
            />
            <InputGroup.Text> minutes</InputGroup.Text>
          </InputGroup>
          <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
        </Form.Group>

        {/* <Form.Group>
          <Form.Label>Advertised Price</Form.Label>
          <InputGroup className={errors.advertised_price ? 'is-invalid' : ''}>
            <InputGroup.Text>US$</InputGroup.Text>
            <Form.Control
              id="advertised_price"
              name="advertised_price"
              type="number"
              placeHolder="Enter Advertised Price"
              onChange={handleChange}
              value={values.advertised_price}
              className={errors.advertised_price ? 'is-invalid' : ''}
            />
            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
          </InputGroup>
        </Form.Group> */}
        
        <Form.Group>
          <Form.Label>Passenger types</Form.Label>
          <Select
            closeMenuOnSelect
            isMulti
            defaultValue={selectedUnits}
            options={options}
            onChange={(value) => handleUnitsChange(value)}
          />
          {errors.product_units ? <div className="EditForm__error">{errors.product_units}</div> : null}
        </Form.Group>
        <Form.Group className="EditForm__form-page2-active">
          <Form.Check
            label="Active"
            id="active"
            name="active"
            type="checkbox"
            onChange={handleChange}
            value={!values.active}
            defaultChecked={values.active}
          />
        </Form.Group>
        <Form.Label>
          Total PAX (the number of passengers that can be booked within a single booking)
        </Form.Label>
        <div className="EditForm__form-page2-pax">
          <InputGroup>
            <InputGroup.Text>Min</InputGroup.Text>
            <Form.Control
              id="pax-min"
              name="paxMin"
              type="number"
              placeHolder="Min"
              onChange={handleChange}
              value={values.paxMin}
              className={errors.paxMin ? 'is-invalid' : ''}
            />
            {errors.paxMin ? <div className="EditForm__error">{errors.paxMin}</div> : null}
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Max</InputGroup.Text>
            <Form.Control
              id="pax-max"
              name="paxMax"
              placeHolder="Max"
              type="number"
              onChange={handleChange}
              value={values.paxMax}
              className={errors.paxMax ? 'is-invalid' : ''}
            />
            <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
          </InputGroup>
        </div>
      </div>
    </div>
  );
}

export default Page2;
