/* eslint-disable max-len */
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "../Guides.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
export default function GuideForm({ formik, cities, guide }) {
  const { values, errors, handleChange, dirty, isSubmitting } = formik;

  const options = cities?.map((city) => ({
    value: city.id,
    label: city.name,
  }));
  const navigate = useNavigate();

  return (
    <>
      {/* <Header /> */}
      <main className="Guides">
        {typeof options === "object" && options.length > 0 ? (
          <Form onSubmit={formik.handleSubmit} className="Guides__form">
            <Form.Group>
              <Form.Label>Full Name</Form.Label>
              <InputGroup className={errors.name ? "is-invalid" : ""}>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                  className={errors.name ? "is-invalid" : ""}
                  placeholder="Enter guide name"
                />
              </InputGroup>
              {errors.name ? (
                <div className="Guides__error">{errors.name}</div>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>City</Form.Label>
              <div
                style={{
                  position: "relative",
                  zIndex: 3,
                }}
              >
                <Select
                  closeMenuOnSelect
                  defaultValue={guide}
                  options={options}
                  onChange={(value) =>
                    formik.setFieldValue("city_id", value.value)
                  }
                />
              </div>
              {errors.city_id ? (
                <div className="Guides__error">{errors.city_id}</div>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>Phone number </Form.Label>
              <InputGroup className={errors.phone ? "is-invalid" : ""}>
                {/* <Form.Control
                  id="phone"
                  name="phone"
                  type="text"
                  onChange={handleChange}
                  value={values.phone}
                  className={errors.phone ? "is-invalid" : ""}
                  placeholder="Enter Phone"
                /> */}
                <PhoneInput
                  international
                  id="phone"
                  name="phone"
                  defaultCountry="CA"
                  placeholder="Enter phone number"
                  // Set value to empty string if undefined, null, or empty
                  value={values.phone} // Make sure values.phone is correctly set from Formik's values
                  onChange={(value) => formik.setFieldValue("phone", value)}
                  className={`custom-phone-input form-control !border-none ${
                    errors.phone ? "is-invalid" : ""
                  } `}
                  style={{
                    outline: "none !important",

                    border: "none !important",
                  }}
                />
              </InputGroup>
              {errors.phone ? (
                <div className="Guides__error">{errors.phone}</div>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>Personal Email</Form.Label>
              <InputGroup className={errors.email_personal ? "is-invalid" : ""}>
                <Form.Control
                  id="email_personal"
                  name="email_personal"
                  type="text"
                  onChange={handleChange}
                  value={values.email_personal}
                  className={errors.email_personal ? "is-invalid" : ""}
                  placeholder="Enter Personal Email"
                  // readOnly={!!guide}
                />
              </InputGroup>
              {errors.email_personal ? (
                <div className="Guides__error">{errors.email_personal}</div>
              ) : null}
            </Form.Group>

            <div className="Guides__buttons">
              <button
                type="submit"
                className="Guides__buttons-save"
                disabled={!dirty || isSubmitting}
              >
                Save
              </button>
              <button
                type="button"
                className="Guides__buttons-cancel"
                // onClick={() => window.location.reload(false)}
                onClick={() => navigate("/guides")}
              >
                Cancel
              </button>
            </div>
          </Form>
        ) : (
          <center>
            <div className="spinner-grow" />
          </center>
        )}
      </main>
    </>
  );
}
