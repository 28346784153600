import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSubscription } from "@apollo/client";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FETCH_CITIES } from "../../api/city";
import styles from "./Sessions.module.scss";
import Calendar from "./Calendar/Calendar";
import Header from "../../components/Header/Header";
import AvailabilityPopup from "./AvailabilityPopup";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GuideAvailability = () => {
  const { data: citiesData } = useSubscription(FETCH_CITIES);
  const [citySelected, setCitySelected] = useState(null);
  const [monthSelected, setMonthSelected] = useState(new Date());
  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [firestoreData, setFirestoreData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState("morning");
  const [guides, setGuides] = useState([]);
  const [uniqueGuides, setUniqueGuides] = useState([]);
  const [guideSelected, setGuideSelected] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const dateRangeButtonLabel = isDatePickerOpen ? "Close" : "Select Date Range";

  // Fetch guides from the backend
  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-see-sight-tours-backend.cloudfunctions.net/boat/guides"
        );
        setGuides(response.data.data);
      } catch (error) {
        console.error("Error fetching guides:", error);
      }
    };

    fetchGuides();
  }, []);


  // Refresh data on button click
  const handleRefresh = () => {
    setIsRefreshing(true);
    setRefresh(true);
  };

  // Filter unique guides by name
  useEffect(() => {
    const uniqueNames = [];
    const filteredGuides = guides?.filter((guide) => {
      if (uniqueNames.includes(guide.name)) {
        return false;
      } else {
        uniqueNames.push(guide.name);
        return true;
      }
    });
    setUniqueGuides(filteredGuides);
  }, [guides]);

  // Fetch city availability data from the backend
  useEffect(() => {
    const fetchData = async () => {
      if (citySelected && selectedRange.startDate && selectedRange.endDate) {
        const cityId = citySelected.id;
        const start = format(selectedRange.startDate, "yyyy-MM-dd");
        const end = format(selectedRange.endDate, "yyyy-MM-dd");

        try {
          const response = await axios.get(
            `https://us-central1-see-sight-tours-backend.cloudfunctions.net/boat/city-availability/${cityId}?start=${start}&end=${end}`
          );
          setFirestoreData(response.data);
          setIsRefreshing(false);
          setRefresh(false);
          if (!response.data || response.data.length === 0 && refresh) {
            toast.error("No data found for the selected city and date range.");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          toast.error("Failed to fetch data.");
        }
      }
    };

    fetchData();
  }, [citySelected, selectedRange, refresh]);

  // Handle city selection from the dropdown
  const handleSelectCity = (event, value) => {
    setCitySelected(value);
    if (value === null) {
      setFirestoreData(null);
    }
  };

  // Handle guide selection from the dropdown
  const handleSelectGuide = (event, value) => {
    if (!citySelected) {
      toast.error("Please select a city first.");
      return;
    }
    setGuideSelected(value);
  };

  const [tempRange, setTempRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  // Handle date range change
  const handleDateRangeChange = (ranges) => {
    setTempRange(ranges.selection);
  };

  // Apply selected date range
  const handleApplyDateRange = () => {
    setSelectedRange(tempRange);
    setIsDatePickerOpen(false);
  };

  const normalizeName = (name) => {
    return name.replace(/\s+/g, "").toLowerCase();
  };
  // Create calendar events based on availability data
  const createEvents = () => {
    if (firestoreData) {
      const events = [];
      const addedTimes = new Set();
  
      firestoreData.forEach((availability) => {
        const { date, guides } = availability;
        const filteredGuides = guideSelected
          ? guides.filter(
              (guide) =>
                normalizeName(guide?.name.trim()) === normalizeName(guideSelected?.name.trim())
            )
          : guides;
  
        const morningGuides = filteredGuides.filter((guide) =>
          guide.availabilities.some((slot) => slot.time === "morning" && !slot.blocked)
        ).length;
  
        const afternoonGuides = filteredGuides.filter((guide) =>
          guide.availabilities.some((slot) => slot.time === "afternoon" && !slot.blocked)
        ).length;
  
        const eveningGuides = filteredGuides.filter((guide) =>
          guide.availabilities.some((slot) => slot.time === "evening" && !slot.blocked)
        ).length;
  
        ["morning", "afternoon", "evening"].forEach((time) => {
          const eventTime = new Date(date);
          let guideCount;
  
          if (time === "morning") {
            eventTime.setHours(9, 0, 0);
            guideCount = morningGuides;
          } else if (time === "afternoon") {
            eventTime.setHours(13, 0, 0);
            guideCount = afternoonGuides;
          } else if (time === "evening") {
            eventTime.setHours(17, 0, 0);
            guideCount = eveningGuides;
          }
  
          const eventKey = `${date}-${time}`;
  
          if (!addedTimes.has(eventKey)) { // Only add events with available guides
            addedTimes.add(eventKey);
            events.push({
              id: eventKey,
              title: `${time.charAt(0).toUpperCase() + time.slice(1)} ${guideCount}`,
              start: new Date(eventTime),
              end: new Date(eventTime.getTime() + 2 * 60 * 60 * 1000),
              allDay: false,
              onClick: () => {
                const selectedTimeGuides = filteredGuides.filter((guide) =>
                  guide.availabilities.some((slot) => slot.time === time && !slot.blocked)
                );
                setPopupData([{ date, guides: selectedTimeGuides }]);
                setSelectedTime(time);
                setOpenPopup(true);
              },
            });
          }
        });
      });
  
      return events;
    }
    return [];
  };
  

  const handleClosePopup = () => {
    setOpenPopup(false);
    setPopupData(null);
  };

  const onSelectEvent = (event) => {
    if (event.onClick) {
      event.onClick();
    }
  };

  return (
    <>
      <Header />
      <main className={styles.sessions}>
        <div style={{ display: "flex", position: "relative" }}>
          <Autocomplete
            size="small"
            disablePortal
            options={citiesData?.boatnew_cities || []}
            sx={{ width: "50%" }}
            renderInput={(params) => <TextField {...params} label="Cities" />}
            getOptionLabel={(option) => option.name}
            groupBy={(option) => option.type}
            onChange={handleSelectCity}
            value={citySelected}
          />

          <Autocomplete
            size="small"
            disablePortal
            options={uniqueGuides || []}
            sx={{ width: "15%", marginLeft: "1rem" }}
            renderInput={(params) => (
              <TextField {...params} label="All Guides" />
            )}
            getOptionLabel={(option) => option.name}
            onChange={handleSelectGuide}
            value={guideSelected}
          />

          <button
            className={styles.dateRangeButton}
            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
            disabled={!citySelected}
            style={{
              backgroundColor: !citySelected ? '#cccccc' : '#007bff',
              cursor: !citySelected ? 'not-allowed' : 'pointer',
            }}
          >
            {dateRangeButtonLabel}
          </button>

          {isDatePickerOpen && (
            <div className={styles.datePickerContainer}>
              <DateRangePicker
                ranges={[tempRange]}
                onChange={handleDateRangeChange}
              />
              <div className={styles.buttons}>
                <button
                  onClick={handleApplyDateRange}
                  className={styles.applyButton}
                  style={{
                    backgroundColor: '#ff6262',
                    color: '#fff',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '10px'
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleApplyDateRange}
                  className={styles.applyButton}
                  style={{
                    backgroundColor: '#ff6262',
                    color: '#fff',
                    marginLeft: '30px',
                    padding: '10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '10px'
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          )}
          <button
            onClick={handleRefresh}
            className={styles.applyRefressButton}
            disabled={!citySelected}
            style={{
              backgroundColor: !citySelected ? '#cccccc' : '#007bff',
              cursor: !citySelected ? 'not-allowed' : 'pointer',
            }}
          >
            {isRefreshing ? "Refreshing" : "Refresh"}
          </button>
        </div>
        <div className={styles.calendarContainer}>
          <div className={styles.calendar}>
            <Calendar
              events={createEvents()}
              onSelectEvent={onSelectEvent}
              monthSelected={monthSelected}
              productsSelected={citySelected}
              setMonthSelected={setMonthSelected}
            />
          </div>
        </div>
      </main>
      <AvailabilityPopup
        open={openPopup}
        onClose={handleClosePopup}
        availabilityData={popupData}
        selectedTime={selectedTime}
      />
    </>
  );
};

export default GuideAvailability;
