import { gql } from "@apollo/client";
import { FRAGMENT_GUIDES } from "./fragments";

export const FETCH_GUIDES_BY_ID = gql`
  query FETCH_GUIDES_BY_ID($id: String!) {
    guide: boatnew_guides_by_pk(id: $id) {
      ...FragmentGuides
    }
    cities: boatnew_cities(order_by: { name: asc }) {
      id
      name
      coordinates
    }
    guide_city: boatnew_cities_guides(where: { guide_id: { _eq: $id } }) {
      city_id
    }
  }
  ${FRAGMENT_GUIDES}
`;

export const FETCH_GUIDES_COUNT_BY_EMAIL = gql`
  query GetGuideCountByEmail($email: String!) {
    guides_aggregate: boatnew_guides_aggregate(
      where: { email_personal: { _eq: $email }, trash: { _eq: false } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const FETCH_GUIDES = gql`
  query FETCH_GUIDES {
    guides: boatnew_guides {
      id
      fullName: full_name
      status
      created_at
    }
    vehicles {
      insurance
      ownership
    }
  }
`;

export const FETCH_ACTIVE_GUIDES = gql`
  query FETCH_GUIDES {
    guides: boatnew_guides(
      where: { status: { _eq: "active" }, trash: { _eq: false } }
    ) {
      id
      fullName: full_name
      status
      created_at
    }
  }
`;

export const SUBSCRIBE_GUIDES = gql`
  subscription SUBSCRIBE_GUIDES {
    guides: boatnew_guides(
      order_by: { created_at: desc }
      where: { trash: { _eq: false } }
    ) {
      id
      fullName: full_name
      status
      created_at
      email_personal
      last_deactivated
      phone
      approved
      verf_doc
      cities_guides {
        city {
          name
        }
      }
      role {
        id
        role
        permissions
      }
      vehicles {
        vehicle {
          insurance
          ownership
          photos
        }
      }
    }
  }
`;

export const SUBSCRIBE_ACTIVE_GUIDES = gql`
  subscription SUBSCRIBE_GUIDES {
    guides: boatnew_guides(
      order_by: { created_at: desc }
      where: { trash: { _eq: false }, status: { _eq: "active" } }
    ) {
      id
      fullName: full_name
      status
      created_at
      email_personal
      last_deactivated
      phone
      cities_guides {
        city {
          name
        }
      }
    }
  }
`;

export const GET_ACTIVE_GUIDES = gql`
  query GET_GUIDES {
    guides: boatnew_guides(
      order_by: { created_at: desc }
      where: { trash: { _eq: false }, status: { _eq: "active" } }
    ) {
      id
      fullName: full_name
      status
      created_at
      email_personal
      last_deactivated
      phone
      cities_guides {
        city {
          name
        }
      }
    }
  }
`;

export const ACTIVE_GUIDE = gql`
  mutation ACTIVATE_GUIDE(
    $guideId: String!
    $status: String!
    $timestamp: timestamptz!
    $android_device_token: String
    $ios_device_token: String
  ) {
    updateGuideStatus: update_boatnew_guides_by_pk(
      pk_columns: { id: $guideId }
      _set: {
        status: $status
        last_deactivated: $timestamp
        android_device_token: $android_device_token
        ios_device_token: $ios_device_token
      }
    ) {
      id
    }
  }
`;

export const DEACTIVE_GUIDE = gql`
  mutation INSERT_GUIDE($guideId: String!, $status: String!) {
    updateGuideStatus: update_boatnew_guides_by_pk(
      pk_columns: { id: $guideId }
      _set: { status: $status }
    ) {
      id
    }
  }
`;

export const MOVE_TO_TRASH = gql`
  mutation GUIDE_TO_TRASH($guideId: String!) {
    guideToTrash: update_boatnew_guides_by_pk(
      pk_columns: { id: $guideId }
      _set: { trash: true }
    ) {
      id
    }
  }
`;

export const INSERT_GUIDE = gql`
  mutation INSERT_GUIDE($input: boatnew_guides_insert_input!) {
    insertGuides: insert_boatnew_guides_one(object: $input) {
      id
    }
  }
`;

export const INSERT_GUIDE_CITY = gql`
  mutation INSERT_GUIDE_CITY($input: boatnew_cities_guides_insert_input!) {
    insertGuidesCity: insert_boatnew_cities_guides_one(object: $input) {
      city_id
    }
  }
`;

export const EDIT_GUIDE = gql`
  mutation EDIT_GUIDE(
    $guideId: String!
    $input: boatnew_guides_set_input!
    $cityId: String!
  ) {
    editGuides: update_boatnew_guides_by_pk(
      pk_columns: { id: $guideId }
      _set: $input
    ) {
      id
    }
    updateCity: update_boatnew_cities_guides(
      where: { guide_id: { _eq: $guideId } }
      _set: { city_id: $cityId }
    ) {
      affected_rows
    }
  }
`;
export const APPROVE_GUIDE = gql`
  mutation APPROVE_GUIDE($guideId: String!) {
    update_boatnew_guides_by_pk(
      pk_columns: { id: $guideId }
      _set: { approved: true, status: "active" }
    ) {
      __typename
    }
  }
`;
export default FETCH_GUIDES;
