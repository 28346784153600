import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Auth } from "aws-amplify";
import { Authenticator, Image, View } from "@aws-amplify/ui-react";
import ReactDOM from "react-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  GraphQLWsLink,
  createHttpLink,
} from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";
import { NavbarProvider } from "./context/navbar.context";
import App from "./App";
import "./index.css";
import AccessControl from "./components/AccessControl/AccessControl";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const hasuraUrl = process.env.REACT_APP_URI;

// have JWT in every request when signed in
const getJwtToken = async () => {
  const token = await Auth.currentAuthenticatedUser().then(
    (cognitoUser) => cognitoUser.signInUserSession.idToken.jwtToken
  );
  return token;
};
const authLink = setContext(async (_, { headers }) => {
  const token = await getJwtToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    },
    connectionParams: {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    },
  };
});

const httpLink = new HttpLink({
  uri: `https://${hasuraUrl}`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${hasuraUrl}`,
    connectionParams: async () => {
      const token = await getJwtToken();
      return { headers: { Authorization: `Bearer ${token}` } };
    },
  })
);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  authLink.concat(wsLink),
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

/* styling for authentication page */
const components = {
  Header() {
    // const TripshepherdLogo = require("./assets/ts_logo.webp");
    return (
      <View className="d-flex justify-content-around">
        <Image
          style={{
            maxWidth: "70%",
            width: "300px",
            height: "auto",
          }}
          src={
            "https://firebasestorage.googleapis.com/v0/b/see-sight-tours-backend.appspot.com/o/BOAT%2Fts_logo.webp?alt=media&token=7f030e86-4e3f-4161-b884-d08530abff53"
          }
          alt="Tripshepperd"
        />
      </View>
    );
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "User Name",
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <Authenticator formFields={formFields} hideSignUp components={components}>
      {({ signOut, user }) => (
        <ApolloProvider client={client}>
          <NavbarProvider>
            <Elements stripe={stripePromise}>
              <AccessControl>
                <App />
              </AccessControl>
            </Elements>
          </NavbarProvider>
        </ApolloProvider>
      )}
    </Authenticator>
  </React.StrictMode>,
  document.getElementById("root")
);
